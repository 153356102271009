import React, { Component } from "react";
import { connect } from "react-redux";
import "./ManageServer.scss";
import * as actions from "../../../store/actions";
import { CRUD_ACTIONS } from "../../../utils/constant";
import TableInforVPS from "./TableInforVPS";
import TableHenGioService from "./TableHenGioService";
import _ from "lodash";

class ManageServer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vpsEditId: "",
      name_vps: "",
      tag: "",
      vesiontool: "",
      ip: "",
      name_service: "",
      trangthai_vps: "",
      action: "CREATE",
      showhengio: false,

      allVpsTimes: this.props.allVpsTimes,
      allOtps: this.props.allOtps,
      listKPI: ""
    };
  }

  componentDidMount() {
    this.props.fetchAllVpsTimes();
    this.props.fetchAllOtps();
    this.props.fetchAllServices();
    setInterval(this.props.fetchAllOtps, 60000);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.allVpsTimes !== this.props.allVpsTimes) {
      this.setState({
        allVpsTimes: this.props.allVpsTimes,
      });
    }

    if (prevProps.allOtps !== this.props.allOtps) {
      this.setState({
        allOtps: this.props.allOtps,
      });
    }

    if (prevProps.listKPI !== this.props.listKPI) {
      this.setState({
        listKPI: (this.props.listKPI)[0],
      });
    }
  }

  handleEditVpsFromParent = (vps) => {
    this.setState({
      action: CRUD_ACTIONS.EDIT,
      vpsEditId: vps.id,
      name_vps: vps.name_vps,
      tag: vps.tag,
      versiontool: vps.versiontool,
      ip: vps.ip,
      name_service: vps.name_service,
      trangthai_vps: vps.trangthai_vps,
    });
  };

  checkValidateInput = () => {
    let isValid = true;
    let arrCheck = ["name_vps", "tag", "versiontool", "ip"];
    for (let i = 0; i < arrCheck.length; i++) {
      if (!this.state[arrCheck[i]]) {
        isValid = false;
        if (arrCheck[i] === "name_vps") {
          alert("Hãy nhập tên VPS!");
        }
        if (arrCheck[i] === "tag") {
          alert("Hãy nhập Tag!");
        }
        if (arrCheck[i] === "versiontool") {
          alert("Hãy nhập Versiontool!");
        }
        if (arrCheck[i] === "ip") {
          alert("Hãy nhập IP!");
        }
        return isValid;
      }
    }
    return isValid;
  };

  onChangeInput = (event, id) => {
    let copyState = { ...this.state };
    let data = event.target.value;
    copyState[id] = data;
    this.setState({
      ...copyState,
    });
  };

  handleSaveVps = () => {
    let { action } = this.state;

    if (action === CRUD_ACTIONS.CREATE) {
      let isValid = this.checkValidateInput();
      if (isValid === false) return;

      this.props.createNewVps({
        name_vps: this.state.name_vps,
        tag: this.state.tag,
        versiontool: this.state.versiontool,
        ip: this.state.ip,
        name_service: "None",
        trangthai_vps: "-",
      });
    }

    if (action === CRUD_ACTIONS.EDIT) {
      let isValid = this.checkValidateInput();
      if (isValid === false) return;

      this.props.editAVpsRedux({
        id: this.state.vpsEditId,
        name_vps: this.state.name_vps,
        tag: this.state.tag,
        versiontool: this.state.versiontool,
        ip: this.state.ip,
        name_service: this.state.name_service,
        trangthai_vps: this.state.trangthai_vps,
      });
    }

    this.setState({
      vpsEditId: "",
      name_vps: "",
      tag: "",
      ip: "",
      versiontool: "",
      name_service: "",
      trangthai_vps: "",
      action: CRUD_ACTIONS.CREATE,
    });
  };

  handleshowhengio = () => {
    this.setState({
      showhengio: !this.state.showhengio,
    });
    this.props.fetchAllVpsTimes();
  };

  handleDeleteTimeout = (item) => {
    if (item.id) {
      this.props.deleteAVpsTime(item.id);
    }
  };

  render() {
    let { name_vps, tag, versiontool, ip, showhengio, allVpsTimes, allOtps, listKPI } =
      this.state;
    return (
      <React.Fragment>
        <div className="manage-schedule-container">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 mb-5">
                <div style={{ height: "50px" }}></div>
                <div className="hang2tren">
                  <div className="haiotp">
                    <div className="divotp">
                      <label className="indam"> 5 Min </label>
                      <input
                        type="text"
                        className="form-control otp"
                        value={allOtps.medium5p}
                      />
                    </div>
                    <div className="divotp">
                      <label className="indam"> 30 Min </label>
                      <input
                        type="text"
                        className="form-control otp"
                        value={allOtps.medium30p}
                      />
                    </div>
                    <div className="divotp">
                      <label className="indam"> 1 Hour </label>
                      <input
                        type="text"
                        className="form-control otp"
                        value={allOtps.medium1h}
                      />
                    </div>
                    <div className="divotp">
                      <label className="indam"> 1 Day </label>
                      <input
                        type="text"
                        className="form-control otp"
                        value={allOtps.medium1d}
                      />
                    </div>
                    <div className="divotp">
                      <label className="indam"> KPI OF DAY </label>
                      <input
                        style={{"color": "blue"}}
                        type="text"
                        className="form-control otp"
                        value={listKPI.kpi}                        
                      />
                    </div>
                    <div className="divotp">
                      <label className="indam"> Current OTP </label>
                      <input
                        style={{"color": "#007f00"}}
                        type="text"
                        className="form-control otp"
                        value={allOtps.currentotp}
                      />
                    </div>
                    
                    <div className="divotp">
                      <label className="indam"> Update </label>
                      <input
                        type="text"
                        className="form-control otp"
                        value={allOtps.timeget}
                        
                      />
                    </div>
                    <div className="divotp">
                      <label className="indam"> Completion</label>
                      <input
                        style={{"color": "#7f007f"}}
                        type="text"
                        className="form-control otp"
                        value={allOtps.timedubao}
                      />
                    </div>                    
                  </div>
                  <div className="hang2tong">
                    <div className="hang2t">
                      <div className="hai2">
                        <label className="indam"> Name VPS </label>
                        <input
                          type="text"
                          className="form-control"
                          value={name_vps}
                          onChange={(event) => {
                            this.onChangeInput(event, "name_vps");
                          }}
                        />
                      </div>
                      <div className="hai2">
                        <label className="indam"> Tag </label>
                        <input
                          type="text"
                          className="form-control"
                          value={tag}
                          onChange={(event) => {
                            this.onChangeInput(event, "tag");
                          }}
                        />
                      </div>
                      <div className="hai2">
                        <label className="indam"> IP </label>
                        <input
                          type="text"
                          className="form-control"
                          value={ip}
                          onChange={(event) => {
                            this.onChangeInput(event, "ip");
                          }}
                        />
                      </div>
                      <div className="hai2" style={{width: "70px"}}>
                        <label className="indam"> VerTool </label>
                        <input
                          type="text"
                          className="form-control"
                          value={versiontool}
                          onChange={(event) => {
                            this.onChangeInput(event, "versiontool");
                          }}
                        />
                      </div>
                      
                    </div>
                    <div className="hang2t">
                      <div className="addAll">
                        <button
                          type="submit"
                          className={
                            this.state.action === CRUD_ACTIONS.EDIT
                              ? "btn btn-warning"
                              : "btn btn-primary"
                          }
                          onClick={this.handleSaveVps}
                          style={{
                            width: "45%",
                            marginBottom: "20px",
                            marginTop: "20px",
                          }}
                        >
                          {this.state.action === CRUD_ACTIONS.EDIT ? (
                            "UPDATE"
                          ) : (
                            <i className="fa fa-plus" aria-hidden="true"></i>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  style={{
                    width: "120px",
                    marginBottom: "10px",
                    marginLeft: "13px",
                  }}
                  className={showhengio ? "btn btn-warning" : "btn btn-primary"}
                  onClick={this.handleshowhengio}
                >
                  {showhengio ? "Close Timer" : "Open Timer"}
                </button>

                {showhengio ? (
                  <TableHenGioService />
                ) : (
                  <TableInforVPS
                    handleEditVpsFromParentKey={this.handleEditVpsFromParent}
                    idEditParent={this.state.idEditParent}
                    action={this.state.action}
                    allOtps = {this.state.allOtps}
                  />
                )}

                {this.props.allVpsTimes &&
                  this.props.allVpsTimes.length > 0 && (
                    <div className="row justify-content-center">
                      <table id="TableVpsTime">
                        <tbody>
                          <tr>
                            <th> STT </th>
                            <th> Name VPS </th>
                            <th> Service hẹn cài đặt </th>
                            <th> Thời gian bắt đầu </th>
                          </tr>
                          {allVpsTimes &&
                            allVpsTimes.length > 0 &&
                            allVpsTimes.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td> {index + 1} </td>
                                  <td> {item.name_vpstime} </td>
                                  <td> {item.name_servicetime} </td>
                                  <td> {item.thoi_gian_hen} </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  )}

                  

                <div style={{ height: "200px" }}></div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allVpss: state.admin.allVpss,
    allVpsTimes: state.admin.allVpsTimes,
    allOtps: state.admin.allOtps,
    listKPI: state.admin.listKPI
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllVpsTimes: () => dispatch(actions.fetchAllVpsTimes()),
    fetchAllOtps: () => dispatch(actions.fetchAllOtps()),
    createNewVps: (data) => dispatch(actions.createNewVps(data)),
    editAVpsRedux: (data) => dispatch(actions.editAVps(data)),
    deleteAVpsTime: (id) => dispatch(actions.deleteAVpsTime(id)),
    fetchAllServices: () => dispatch(actions.fetchAllServices()),    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageServer);
