import actionTypes from "../actions/actionTypes";

const initialState = {
  allServices: [],
  listKPI: [],
  allVpss: [],
  allVpsTimes: [],
  Vps: [],
  allOtps: [],
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ALL_SERVICES_SUCCESS:
      state.allServices = action.dataDr.listService;
      state.listKPI = action.dataDr.listKPI;
      return {
        ...state,
      };

    case actionTypes.FETCH_ALL_SERVICES_FAILDED:
      state.allServices = [];
      return {
        ...state,
      };

    case actionTypes.FETCH_ALL_OTPS_SUCCESS:
      state.allOtps = action.dataDr;
      return {
        ...state,
      };

    case actionTypes.FETCH_ALL_OTPS_FAILDED:
      state.allOtps = [];
      return {
        ...state,
      };

    case actionTypes.FETCH_ALL_VPSS_SUCCESS:
      state.allVpss = action.dataDr;
      return {
        ...state,
      };

    case actionTypes.FETCH_ALL_VPSS_FAILDED:
      state.allVpss = [];
      return {
        ...state,
      };

    case actionTypes.FETCH_ALL_VPSTIMES_SUCCESS:
      state.allVpsTimes = action.dataDr;
      return {
        ...state,
      };

    case actionTypes.FETCH_ALL_VPSTIMES_FAILDED:
      state.allVpsTimes = [];
      return {
        ...state,
      };

    case actionTypes.FETCH_VPS_SUCCESS:
      state.Vps = action.dataDr;
      return {
        ...state,
      };

    case actionTypes.FETCH_VPS_FAILDED:
      state.Vps = [];
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default adminReducer;
