import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import ManageServer from "../containers/System/Service/ManageServer";

class Service extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="system-container">
          <div className="system-list">
            <Switch>
              <Route
                path="/service/manage-schedule"
                component={ManageServer}
              />{" "}
            </Switch>{" "}
          </div>{" "}
        </div>{" "}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Service);
