export const path = {
  HOME: "/",
  SYSTEM: "/system",
  DETAIL_DOCTOR: "/detail-service/:id",
};


export const CRUD_ACTIONS = {
  CREATE: "CREATE",
  EDIT: "EDIT",
  DELETE: "DELETE",
  READ: "READ",
};