import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import "./TableHenGioService.scss";
import * as actions from "../../../store/actions";
import ModalDelete from "./ModalDelete";

class TableHenGioService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listServices: [],
      listVpss: [],

      idEdit: this.props.idEditParent,
      isOpenModalDelete: false,
      vpsDelete: {},
      name_service: "",
      kpi: "",

      isOpenInputService: false,
      isChooseCheckbox: false,
      uncheck: false,
      set_name_service: "",

      listCheckbox: [],
      name_service_hengio: "",
      thoi_gian_hen: "",
    };
  }

  componentDidMount() {
    this.props.fetchAllServices();
    this.props.fetchAllVpss();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.allServices !== this.props.allServices) {
      let dataSelect = this.buildDataInputSelect(this.props.allServices);
      this.setState({
        listServices: dataSelect,
      });
    }

    if (
      prevProps.allVpss !== this.props.allVpss ||
      prevProps.idEditParent !== this.props.idEditParent
    ) {
      this.setState({
        listVpss: this.props.allVpss,
        idEdit: this.props.idEditParent,
        vpsDelete: this.state.vpsDelete,
      });
    }
  }

  buildDataInputSelect = (inputData) => {
    let result = [];
    if (inputData && inputData.length > 0) {
      inputData.map((item, index) => {
        let object = {};
        object.value = item.name_service;
        object.label = item.name_service;
        result.push(object);
      });
    }
    return result;
  };

  handleChangeSelect = async (event) => {
    let name_service_hengio = event.value;

    this.setState({
      name_service_hengio: name_service_hengio,
    });
  };

  onChangeInput = (event, id) => {
    let copyState = { ...this.state };
    let data = event.target.value;
    copyState[id] = data;
    this.setState({
      ...copyState,
    });
  };

  handleOnChangeCheckbox = (event) => {
    let check = event.target.checked;
    let idcheck = event.target.id;

    let listCheckcopy = this.state.listCheckbox;

    if (check) {
      listCheckcopy.push(idcheck);
      if (listCheckcopy.length >= 1) {
        this.setState({
          isChooseCheckbox: true,
        });
      }
    }

    if (!check) {
      for (let i = 0; i < listCheckcopy.length; i++) {
        if (listCheckcopy[i] === idcheck) {
          listCheckcopy.splice(i, 1);
        }
      }
    }

    this.setState({
      listCheckbox: listCheckcopy,
    });
  };

  handleSetHenGio = () => {
    let arrchecbox = this.state.listCheckbox;
    if (!this.state.name_service_hengio) {
      alert("Chọn tên service cần hẹn giờ!");
      return;
    }
    if (!this.state.thoi_gian_hen) {
      alert("Nhập thời gian hẹn!");
      return;
    } else {
      let gach = this.state.thoi_gian_hen.split("-").length;
      let cach = this.state.thoi_gian_hen.split(" ").length;
      let haicham = this.state.thoi_gian_hen.split(":").length;
      let nam = Number(this.state.thoi_gian_hen.split("-")[0]);
      if (gach !== 3 || cach !== 2 || haicham !== 3 || nam < 1000) {
        alert("Định đạng thời gian chưa đúng!");
        return;
      }
    }

    if (arrchecbox.length < 1) {
      alert("Chọn VPS cần hẹn giờ!");
      return;
    } else {
      let listidvps = arrchecbox;
      let namesv = this.state.name_service_hengio;
      let timehengio = this.state.thoi_gian_hen;

      let data = { listidvps, namesv, timehengio };
      this.props.handleSetTimeService(data);

      this.handleDeleteChecked(listidvps);
      document.getElementById(1).checked = false;

      this.setState({
        name_service_hengio: "",
        thoi_gian_hen: "",
      });
    }
  };

  handleDeleteChecked = (arrVpsCheck) => {
    for (let i = 0; i < arrVpsCheck.length; i++) {
      document.getElementById(arrVpsCheck[i]).checked = false;
    }
    this.setState({
      listCheckbox: [],
    });
  };

  handleAllCheckbox = (event)=>{
    let check = event.target.checked;
    let vpss = this.state.listVpss;
    let arrcheck = [];

    if(check){
      for (let i = 0; i < vpss.length; i++) {
        arrcheck.push(vpss[i].id);
        document.getElementById(vpss[i].id).checked = true;
      }
      this.setState({
        listCheckbox: arrcheck
      });
    }
    
    if(!check){
      for (let i = 0; i < vpss.length; i++) {
        document.getElementById(vpss[i].id).checked = false;
      }
      this.setState({
        listCheckbox: arrcheck
      });
    }
    
    
  }

  render() {
    let arrAllVps = this.state.listVpss;
    let {
      idEdit,
      vpsDelete,
      name_service,
      kpi,
      isOpenInputService,
      isChooseCheckbox,
      set_name_service,
      name_service_hengio,
      thoi_gian_hen,
    } = this.state;

    return (
      <React.Fragment>
        <div className="manage-schedule-container">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-3 form-group">
                <Select
                
                  className="servicehengio"
                  value={{
                    value: this.state.name_service_hengio,
                    label: this.state.name_service_hengio,
                  }}
                  onChange={(event) => this.handleChangeSelect(event)}
                  options={this.state.listServices}
                />
              </div>

              <div className="col-6 form-group">
                <input
                className="form-control"
                  placeholder="2024-03-31 08:00:00"
                  style={{
                    fontSize: "20px",
                    width: "100%",
                    height: "35px",
                    marginTop: "2px",
                  }}
                  value={this.state.thoi_gian_hen}
                  onChange={(event) =>
                    this.onChangeInput(event, "thoi_gian_hen")
                  }
                  type="text"
                />
              </div>
              <div className="col-3 form-group">
                <button
                  className="btn btn-primary"
                  style={{
                    fontSize: "20px",
                    color: "White",
                    width: "100%",
                    height: "33px",
                    marginBottom: "0px",
                    marginTop: "3px",
                    marginLeft: "10px",
                    textAlign: "center"
                  }}
                  onClick={this.handleSetHenGio}
                >
                  SET
                </button>
              </div>

              <table id="TableHenGioService">
                <tbody>
                  <tr>
                    <th>
                    <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  type="checkbox"
                                  className="form-check-input checkboxVPS"
                            
                                  id = {1}
                                  onChange={(event) =>
                                    this.handleAllCheckbox(event)
                                  }
                                ></input>
                              </label>
                            </div>
                    </th>
                    <th> STT </th>
                    <th> Name </th>
                    <th>Service</th>
                  </tr>
                  {arrAllVps &&
                    arrAllVps.length > 0 &&
                    arrAllVps.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  type="checkbox"
                                  className="form-check-input checkboxVPS"
                                  name={item.name_vps}
                                  id={item.id}
                                  value={item.name_vps}
                                  onChange={(event) =>
                                    this.handleOnChangeCheckbox(event)
                                  }
                                ></input>
                              </label>
                            </div>
                          </td>
                          <td> {index + 1} </td>
                          <td> {item.name_vps} </td>
                          <td>
                            {
                              <div className="col-12 form-group">
                                <Select
                                  value={{
                                    value: item.name_service,
                                    label: item.name_service,
                                  }}
                                  options={this.state.listServices}
                                />
                              </div>
                            }
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allServices: state.admin.allServices,
    allVpss: state.admin.allVpss,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllVpss: () => dispatch(actions.fetchAllVpss()),
    fetchAllServices: () => dispatch(actions.fetchAllServices()),
    createNewService: (data) => dispatch(actions.createNewServiceAction(data)),
    editAVpsRedux: (data) => dispatch(actions.editAVps(data)),
    deleteAVpsRedux: (id) => dispatch(actions.deleteAVps(id)),
    handleSetFileVPSActions: (data) =>
      dispatch(actions.handleSetFileVPSActions(data)),
    handleSetStatusVPSActions: (data) =>
      dispatch(actions.handleSetStatusVPSActions(data)),
    setNameVpsNoneActions: (name) =>
      dispatch(actions.setNameVpsNoneActions(name)),

    handleSetTimeService: (data) =>
      dispatch(actions.handleSetTimeService(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableHenGioService);
