import actionTypes from "./actionTypes";
import {
  getAllServices,
  createNewService,
  saveDetailServiceService,
} from "../../services/serviceService";
import {
  getAllVpss,
  setAutoVps,
  createNewVpsService,
  editVpsService,
  deleteVpsService,
  handleSetFileVPS,
  handleSetStatusVPS,
  setNameVpsNone,
  setTimeService,
  getAllVpsTimes,
  deleteVpsTimeService,
  getAllOtps,
} from "../../services/vpsService";
import { toast } from "react-toastify";

export const handleSetFileVPSActions = (data) => {
  return async (dispatch, getState) => {
    try {
      await handleSetFileVPS(data);
    } catch (error) {
      console.log("FETCH_ALL_VPSS_FAILDED: ", error);
    }
  };
};

export const handleSetStatusVPSActions = (data) => {
  return async (dispatch, getState) => {
    try {
      await handleSetStatusVPS(data);
    } catch (error) {
      console.log("FETCH_ALL_VPSS_FAILDED: ", error);
    }
  };
};

export const fetchAllVpss = () => {
  return async (dispatch, getState) => {
    try {
      let res = await getAllVpss("All");
      if (res && res.errCode === 0) {
        dispatch({
          type: actionTypes.FETCH_ALL_VPSS_SUCCESS,
          dataDr: res.data,
        });
      } else {
        dispatch({
          type: actionTypes.FETCH_ALL_VPSS_FAILDED,
        });
      }
    } catch (error) {
      console.log("FETCH_ALL_VPSS_FAILDED: ", error);
      dispatch({
        type: actionTypes.FETCH_ALL_VPSS_FAILDED,
      });
    }
  };
};

export const fetchAllVpsTimes = () => {
  return async (dispatch, getState) => {
    try {
      let res = await getAllVpsTimes("All");
      if (res && res.errCode === 0) {
        dispatch({
          type: actionTypes.FETCH_ALL_VPSTIMES_SUCCESS,
          dataDr: res.data,
        });
      } else {
        dispatch({
          type: actionTypes.FETCH_ALL_VPSTIMES_FAILDED,
        });
      }
    } catch (error) {
      console.log("FETCH_ALL_VPSTIMES_FAILDED: ", error);
      dispatch({
        type: actionTypes.FETCH_ALL_VPSTIMES_FAILDED,
      });
    }
  };
};

export const setNameVpsNoneActions = (name) => {
  return async (dispatch, getState) => {
    try {
      let name1 = name;
      let data = await setNameVpsNone(name1);
      if (data) {
        dispatch(fetchAllVpss());
      }
    } catch (error) {
      dispatch(deleteVpsFailded());
    }
  };
};

export const handleSetTimeService = (data) => {
  return async (dispatch, getState) => {
    try {
      let data1 = await setTimeService(data);
      if (data1) {
        dispatch(fetchAllVpss());
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const createNewVps = (data) => {
  return async (dispatch, getState) => {
    try {
      let res = await createNewVpsService(data);
      if (res && res.errCode === 0) {
        dispatch(saveVpsSuccess());
        dispatch(fetchAllVpss());
      } else {
        dispatch(saveVpsFailded());
      }
    } catch (error) {
      dispatch(saveVpsFailded());
    }
  };
};

export const saveVpsSuccess = () => ({
  type: actionTypes.CREATE_VPS_SUCCESS,
});

export const saveVpsFailded = () => ({
  type: actionTypes.CREATE_VPS_FAILDED,
});

export const deleteAVps = (vpsId) => {
  return async (dispatch, getState) => {
    try {
      let res = await deleteVpsService(vpsId);
      if (res && res.errCode === 0) {
        dispatch(deleteVpsSuccess());
        dispatch(fetchAllVpss());
      } else {
        dispatch(deleteVpsFailded());
      }
    } catch (error) {
      dispatch(deleteVpsFailded());
    }
  };
};

export const deleteVpsSuccess = () => ({
  type: actionTypes.DELETE_VPS_SUCCESS,
});

export const deleteVpsFailded = () => ({
  type: actionTypes.DELETE_VPS_FAILDED,
});

export const deleteAVpsTime = (vpsId) => {
  return async (dispatch, getState) => {
    try {
      let res = await deleteVpsTimeService(vpsId);
      if (res && res.errCode === 0) {
        dispatch(deleteVpsTimeSuccess());
        dispatch(fetchAllVpsTimes());
      } else {
        dispatch(deleteVpsTimeFailded());
      }
    } catch (error) {
      dispatch(deleteVpsTimeFailded());
    }
  };
};

export const deleteVpsTimeSuccess = () => ({
  type: actionTypes.DELETE_VPSTIME_SUCCESS,
});

export const deleteVpsTimeFailded = () => ({
  type: actionTypes.DELETE_VPSTIME_FAILDED,
});

export const editAVps = (data) => {
  return async (dispatch, getState) => {
    try {
      let res = await editVpsService(data);
      if (res && res.errCode === 0) {
        dispatch(editVpsSuccess());
        dispatch(fetchAllVpss());
      } else {
        dispatch(editVpsFailded());
      }
    } catch (error) {
      dispatch(editVpsFailded());
    }
  };
};

export const editVpsSuccess = () => ({
  type: actionTypes.EDIT_VPS_SUCCESS,
});

export const editVpsFailded = () => ({
  type: actionTypes.EDIT_VPS_FAILDED,
});

export const fetchAllServices = () => {
  return async (dispatch, getState) => {
    try {
      let res = await getAllServices();
      if (res && res.errCode === 0) {
        let m = res.data;
        let KPI = m.splice(9, 1);
        let data = { listService: m, listKPI: KPI };
        dispatch({
          type: actionTypes.FETCH_ALL_SERVICES_SUCCESS,
          dataDr: data,
        });
      } else {
        dispatch({
          type: actionTypes.FETCH_ALL_SERVICES_FAILDED,
        });
      }
    } catch (error) {
      console.log("FETCH_ALL_SERVICES_FAILDED: ", error);
      dispatch({
        type: actionTypes.FETCH_ALL_SERVICES_FAILDED,
      });
    }
  };
};

export const fetchAllOtps = () => {
  return async (dispatch, getState) => {
    try {
      let res = await getAllOtps();
      let res2 = await getAllServices();

      if (res && res.errCode === 0 && res2 && res2.errCode === 0) {
        let m = res2.data;
        let listKPI = m.splice(9, 1);
        let KPI = Number(listKPI[0].kpi);

        let timedubao = "";
        let data1 = res.data;
        let timeget = data1.timeget.split(":");

        if (data1.currentotp >= KPI) {
          data1 = {
            ...data1,
            timedubao: "Ok",
            medium30p: "-",
            medium1h: "-",
            medium1d: "-",
          };
        } else {
          if (data1.medium5p !== 0) {
            let gio = Math.floor((KPI - data1.currentotp) / data1.medium5p);
            let phut = Math.floor(
              ((KPI - data1.currentotp) / data1.medium5p - gio) * 60
            );
            // if((gio === Number(timeget[0]) && phut >= Number(timeget[1])) || gio > Number(timeget[0]))

            if (Number(timeget[1]) + phut >= 60) {
              gio = gio + Number(timeget[0]) + 1;
              phut = Number(timeget[1]) + phut - 60;
            } else {
              gio = Number(timeget[0]) + gio;
              phut = Number(timeget[1]) + phut;
            }

            if (phut < 10) {
              timedubao = gio.toString() + "h0" + phut.toString();
            } else {
              timedubao = gio.toString() + "h" + phut.toString();
            }

            data1 = {
              ...data1,
              timedubao: timedubao,
              timeget: timeget[0] + "h" + timeget[1],
            };
          } else {
            data1 = {
              ...data1,
              timedubao: "-",
              timeget: timeget[0] + "h" + timeget[1],
            };
          }
        }

        dispatch({
          type: actionTypes.FETCH_ALL_OTPS_SUCCESS,
          dataDr: data1,
        });
      } else {
        dispatch({
          type: actionTypes.FETCH_ALL_OTPS_FAILDED,
        });
      }
    } catch (error) {
      console.log("FETCH_ALL_OTPS_FAILDED: ", error);
      dispatch({
        type: actionTypes.FETCH_ALL_OTPS_FAILDED,
      });
    }
  };
};

export const saveDetailService = (data) => {
  return async (dispatch, getState) => {
    try {
      let res = await saveDetailServiceService(data);
      if (res && res.errCode === 0) {
        toast.success("Save Infor Service succeed!");
        dispatch({
          type: actionTypes.SAVE_DETAIL_SERVICE_SUCCESS,
        });
      } else {
        toast.error("Save Infor Service error!");
        dispatch({
          type: actionTypes.SAVE_DETAIL_SERVICE_FAILDED,
        });
      }
    } catch (error) {
      console.log("SAVE_DETAIL_SERVICE_FAILDED: ", error);
      toast.error("Save Infor Service error!");
      dispatch({
        type: actionTypes.SAVE_DETAIL_SERVICE_FAILDED,
      });
    }
  };
};

export const fetchSetAutoService = (data) => {
  return async (dispatch, getState) => {
    try {
      let res = await setAutoVps(data);
      if (res) {
        dispatch({
          type: actionTypes.FETCH_ALL_VPSS_SUCCESS,
          dataDr: res,
        });
      } else {
        dispatch({
          type: actionTypes.FETCH_VPS_FAILDED,
        });
      }
    } catch (error) {
      console.log("FETCH_VPS_FAILDED: ", error);
      dispatch({
        type: actionTypes.FETCH_VPS_FAILDED,
      });
    }
  };
};

export const createNewServiceAction = (data) => {
  return async (dispatch, getState) => {
    try {
      let res = await createNewService(data);
      if (res && res.errCode === 0) {
        dispatch(saveServiceSuccess());
        dispatch(fetchAllServices());
      } else {
        dispatch(saveServiceFailded());
      }
    } catch (error) {
      dispatch(saveServiceFailded());
    }
  };
};

export const saveServiceSuccess = () => ({
  type: actionTypes.CREATE_SERVICE_SUCCESS,
});

export const saveServiceFailded = () => ({
  type: actionTypes.CREATE_SERVICE_FAILDED,
});
