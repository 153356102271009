// import axios from "axios";
import instance from "../axios";

const getAllVpss = (id) => {
    return instance.get(`/get-all-vpss?id=${id}`);
};

const getAllVpsTimes = (id) => {
    return instance.get(`/get-all-vpstimes?id=${id}`);
};

const createNewVpsService = (data) => {
    return instance.post(`/create-new-vps`, data);
};

const editVpsService = (data) => {
    return instance.put(`/update`, data);
};

const deleteVpsService = (id) => {
    return instance.delete(`/delete-vps?id=${id}`);
};


const setAutoVps = (data) => {
    return instance.put(`/setautoservice`, data);
};

const handleSetFileVPS = (data) => {
    return instance.put(`/setfilevps`, data);
};

const handleSetStatusVPS = (data) => {
    return instance.put(`/setstatusvps`, data);
};

const setNameVpsNone = (name) => {
    return instance.get(`/setnamevpsnone?name=${name}`);
};


const setTimeService = (data) => {
    return instance.put(`/settimeservice`, data);
};

const deleteVpsTimeService = (id) => {
    return instance.delete(`/delete-vpstime?id=${id}`);
};

const getAllOtps = () => {
    return instance.get(`/get-medium-otp`);
};

export {
    getAllVpss,
    setAutoVps,
    createNewVpsService,
    editVpsService,
    deleteVpsService,
    handleSetFileVPS,
    handleSetStatusVPS,
    setNameVpsNone,
    setTimeService,
    deleteVpsTimeService,
    getAllVpsTimes,
    getAllOtps
};