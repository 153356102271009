import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import "./ModalDelete.scss";
import _ from "lodash";

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  Row,
} from "reactstrap";

class ModalDelete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vpsChild: this.props.vps,
    };
  }

  componentDidMount() {
    let vpsCopy = this.props.vps;
    if (vpsCopy && !_.isEmpty(vpsCopy)) {
      this.setState({
        vpsChild: vpsCopy,
      });
    }
  }

  render() {
    return (
      <div className="text-center">
        <Modal
          isOpen={this.props.isOpen}
          toggle={() => {
            this.props.toggleFormParent();
          }}
        >
          <ModalHeader
            toggle={() => {
              this.props.toggleFormParent();
            }}
          ></ModalHeader>
          <ModalBody>
            <Form>
              <Row> Bạn chắc chắn muốn xóa? </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                this.props.handleDeleteVps(this.props.vps);
                // this.props.handleDeleteVps(this.state.vpsChild);
              }}
            >
              Xóa
            </Button>
            <Button onClick={this.props.toggleFormParent}> Hủy </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteAVpsRedux: (id) => dispatch(actions.deleteAVps(id)),
    fetchVpsRedux: () => dispatch(actions.fetchAllVpss()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDelete);
