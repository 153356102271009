import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import "./TableInforVPS.scss";
import * as actions from "../../../store/actions";
import ModalDelete from "./ModalDelete";
import _ from "lodash";

class TableInforVPS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listServices: [],
      listVpss: [],

      idEdit: this.props.idEditParent,
      isOpenModalDelete: false,
      vpsDelete: {},
      name_service: "",
      kpi: "",

      isOpenInputService: false,
      listCheckbox: [],
      isChooseCheckbox: false,
      uncheck: false,

      set_name_service: "",
      allOtps: this.props.allOtps
    };
  }

  componentDidMount() {
    this.props.fetchAllServices();
    this.props.fetchAllVpss();
    setInterval(this.props.fetchAllVpss, 1000);
    
    let allOtps = this.props.allOtps;
    if (allOtps && !_.isEmpty(allOtps)) {
      this.setState({
        allOtps: allOtps,
      });
    }    
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.allServices !== this.props.allServices) {
      let dataSelect = this.buildDataInputSelect(this.props.allServices);
      this.setState({
        listServices: dataSelect,
      });
    }

    if (
      prevProps.allVpss !== this.props.allVpss ||
      prevProps.idEditParent !== this.props.idEditParent
    ) {
      this.setState({
        listVpss: this.props.allVpss,
        idEdit: this.props.idEditParent,
        vpsDelete: this.state.vpsDelete,
      });
    }
  }

  buildDataInputSelect = (inputData) => {
    let result = [];
    if (inputData && inputData.length > 0) {
      inputData.map((item, index) => {
        let object = {};
        object.value = item.name_service;
        object.label = item.name_service;
        result.push(object);
      });
    }
    return result;
  };

  handleChangeSelect = async (event, index) => {
    let name_service_vps = event.value;
    let idVPS = "";
    let nameVPS = "";
    let tag = "";
    let versiontool = "";
    let trangthai_vps = "";

    let allVpss = this.state.listVpss;

    for (let i = 0; i < allVpss.length; i++) {
      if (i === index) {
        idVPS = allVpss[i].id;
        nameVPS = allVpss[i].name_vps;
        tag = allVpss[i].tag;
        versiontool = allVpss[i].versiontool;
        trangthai_vps = allVpss[i].trangthai_vps;
      }
    }

    if (name_service_vps && nameVPS) {
      this.props.editAVpsRedux({
        id: idVPS,
        name_vps: nameVPS,
        name_service: name_service_vps,
        tag: tag,
        versiontool: versiontool,
        trangthai_vps: trangthai_vps,
      });
    }
  };

  handleChangeSelectSetService = async (event) => {
    let set_name_service = event.value;
    this.setState({
      set_name_service: set_name_service,
    });
  };

  handleDeleteVps = (vps) => {
    this.props.deleteAVpsRedux(vps.id);
    this.toggleModalDelete();
  };

  handleDeleteChecked = (arrVpsCheck) => {
    for (let i = 0; i < arrVpsCheck.length; i++) {
      this.props.deleteAVpsRedux(arrVpsCheck[i]);
      document.getElementById(arrVpsCheck[i]).checked = false;
    }
    this.setState({
      listCheckbox: [],
      isChooseCheckbox: !this.state.isChooseCheckbox,
    });
  };

  handleEditVps = (vps) => {
    this.props.handleEditVpsFromParentKey(vps);
    let id = vps.id;
    this.setState({
      idEdit: id,
    });
  };

  toggleModalDelete = () => {
    this.setState({
      isOpenModalDelete: !this.state.isOpenModalDelete,
    });
  };

  handleDisplayConfirmDelete = (item) => {
    this.setState({
      isOpenModalDelete: true,
      vpsDelete: item,
    });
  };

  checkValidateInput = () => {
    let isValid = true;
    let arrCheck = ["name_service", "kpi"];
    for (let i = 0; i < arrCheck.length; i++) {
      if (!this.state[arrCheck[i]]) {
        isValid = false;
        if (arrCheck[i] === "name_service") {
          alert("Hãy nhập tên service!");
        }
        if (arrCheck[i] === "kpi") {
          alert("Hãy nhập KPI!");
        }
        return isValid;
      }
    }
    return isValid;
  };

  onChangeInput = (event, id) => {
    let copyState = { ...this.state };
    let data = event.target.value;
    copyState[id] = data;
    this.setState({
      ...copyState,
    });
  };

  openInputService = () => {
    this.setState({
      isOpenInputService: !this.state.isOpenInputService,
    });
  };

  handleCreateNewService = () => {
    let inputService = this.state.isOpenInputService;

    if (!inputService) {
      this.openInputService();
      return;
    } else {
      let isValid = this.checkValidateInput();
      if (isValid === false) return;

      this.props.createNewService({
        name_service: this.state.name_service,
        kpi: this.state.kpi,
      });

      this.setState({
        name_service: "",
        kpi: "",
      });

      this.openInputService();
    }
  };

  handleSetNone = () => {
    let name = this.state.set_name_service;
    let listsv = this.state.listServices;
    let n = 0;
    for (let i = 0; i < listsv.length; i++) {
      if (name === listsv[i].value) {
        n = n + 1;
      }
    }
    if (n >= 1) {
      this.props.setNameVpsNoneActions(name);
      this.setState({
        set_name_service: "",
      });
    } else {
      alert("Hãy chọn service!");
      return;
    }
  };

  handleOnChangeCheckbox = (event) => {
    let check = event.target.checked;
    let idcheck = event.target.id;

    let listCheckcopy = this.state.listCheckbox;

    if (check) {
      listCheckcopy.push(idcheck);
      if (listCheckcopy.length >= 1) {
        this.setState({
          isChooseCheckbox: true,
        });
      }
    }

    if (!check) {
      for (let i = 0; i < listCheckcopy.length; i++) {
        if (listCheckcopy[i] === idcheck) {
          listCheckcopy.splice(i, 1);
        }
      }
      if (!check && listCheckcopy.length >= 1) {
        this.setState({
          isChooseCheckbox: true,
        });
      } else {
        this.setState({
          isChooseCheckbox: false,
        });
      }
    }

    this.setState({
      listCheckbox: listCheckcopy,
    });
  };


  handleAllCheckbox = (event)=>{
    let check = event.target.checked;
    let vpss = this.state.listVpss;
    let arrcheck = [];

    if(check){
      for (let i = 0; i < vpss.length; i++) {
        arrcheck.push(vpss[i].id);
        document.getElementById(vpss[i].id).checked = true;
      }
      this.setState({
        listCheckbox: arrcheck,
        isChooseCheckbox: true,
      });
    }
    
    if(!check){
      for (let i = 0; i < vpss.length; i++) {
        document.getElementById(vpss[i].id).checked = false;
      }
      this.setState({
        listCheckbox: arrcheck,
        isChooseCheckbox: false,
      });
    }
    
    // document.getElementsByClassName("form-check").checked = true;
    
  }

  
  render() {
    let arrAllVps = this.state.listVpss;
    let {
      idEdit,
      vpsDelete,
      name_service,
      kpi,
      isOpenInputService,
      isChooseCheckbox,
      set_name_service,
    } = this.state;

    return (
      <React.Fragment>
      
              {isOpenInputService && (
                <div className="hang2">
                  <div className="hai2">
                    <label className="indam"> Name Service </label>
                    <input
                      type="text"
                      className="form-control"
                      value={name_service}
                      onChange={(event) => {
                        this.onChangeInput(event, "name_service");
                      }}
                    />
                  </div>
                  <div className="hai2">
                    <label className="indam"> KPI </label>
                    <input
                      type="text"
                      className="form-control"
                      value={kpi}
                      onChange={(event) => {
                        this.onChangeInput(event, "kpi");
                      }}
                    />
                  </div>

                  <button
                    className="hideService"
                    onClick={this.openInputService}
                  >
                    <i className="fa fa-minus" aria-hidden="true"></i>
                  </button>
                </div>
              )}

              {!isOpenInputService && (
                <div className="hang2">
                  <div className="col-4 form-group">
                    <Select
                      value={{
                        value: set_name_service,
                        label: set_name_service,
                      }}
                      onChange={(event) =>
                        this.handleChangeSelectSetService(event)
                      }
                      options={this.state.listServices}
                    />
                  </div>
                </div>
              )}

              <div className="divXoaCheckbox">
                {isChooseCheckbox && (
                  <button
                    onClick={() =>
                      this.handleDeleteChecked(this.state.listCheckbox)
                    }
                    className="xoaCheckbox"
                  >
                    <i className="fa fa-trash" aria-hidden="true"></i>
                  </button>
                )}
              </div>

              <table id="TableInforVPS">
                <tbody>
                  <tr>
                    <th>
                    <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  type="checkbox"
                                  className="form-check-input checkboxVPS"
                            
                                  
                                  onChange={(event) =>
                                    this.handleAllCheckbox(event)
                                  }
                                ></input>
                              </label>
                            </div>
                    
                    </th>
                    <th> STT </th>
                    <th> Name </th>
                    <th> Status </th>
                    <th>
                      <button
                        type="submit"
                        className="btnNone"
                        onClick={this.handleSetNone}
                      >
                        <i className="fa fa-minus" aria-hidden="true"></i>
                      </button>
                      Service
                      <button
                        type="submit"
                        className={
                          isOpenInputService
                            ? "btnAddService2"
                            : "btnAddService1"
                        }
                        onClick={this.handleCreateNewService}
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      </button>
                    </th>
                    <th> Actions </th>
                  </tr>
                  {arrAllVps &&
                    arrAllVps.length > 0 &&
                    arrAllVps.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  type="checkbox"
                                  className="form-check-input checkboxVPS"
                                  name={item.name_vps}
                                  id={item.id}
                                  value={item.name_vps}
                                  onChange={(event) =>
                                    this.handleOnChangeCheckbox(event)
                                  }
                                ></input>
                              </label>
                            </div>
                          </td>
                          <td> {index + 1} </td>
                          <td>                           
                          {item.name_vps}        
                           </td>
                          <td> {item.trangthai_vps} </td>
                          <td>
                            {
                              <div className="col-12 form-group">
                                <Select
                                  value={{
                                    value: item.name_service,
                                    label: item.name_service,
                                  }}
                                  onChange={(event) =>
                                    this.handleChangeSelect(event, index)
                                  }
                                  options={this.state.listServices}
                                />
                              </div>
                            }
                          </td>

                          <td>
                            <button
                              className={
                                idEdit !== item.id
                                  ? "btn-primary"
                                  : "btn btn-warning"
                              }
                              onClick={() => this.handleEditVps(item)}
                            >
                              {idEdit !== item.id ? "Edit" : "Editing"}
                            </button>
                            <button
                              className="btn-delete"
                              onClick={() =>
                                this.handleDisplayConfirmDelete(item)
                              }
                            >
                              Delete
                            </button>
                          </td>

                          {
                            <ModalDelete
                              isOpen={this.state.isOpenModalDelete}
                              toggleFormParent={this.toggleModalDelete}
                              vps={vpsDelete}
                              handleDeleteVps={this.handleDeleteVps}
                            />
                          }
                        </tr>
                      );
                    })}
                </tbody>
              </table>
          
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allServices: state.admin.allServices,
    allVpss: state.admin.allVpss,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllVpss: () => dispatch(actions.fetchAllVpss()),
    fetchAllServices: () => dispatch(actions.fetchAllServices()),
    createNewService: (data) => dispatch(actions.createNewServiceAction(data)),
    editAVpsRedux: (data) => dispatch(actions.editAVps(data)),
    deleteAVpsRedux: (id) => dispatch(actions.deleteAVps(id)),
    handleSetFileVPSActions: (data) =>
      dispatch(actions.handleSetFileVPSActions(data)),
    handleSetStatusVPSActions: (data) =>
      dispatch(actions.handleSetStatusVPSActions(data)),
    setNameVpsNoneActions: (name) =>
      dispatch(actions.setNameVpsNoneActions(name)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TableInforVPS);
