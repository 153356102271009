// import axios from "axios";
import instance from "../axios";

const getAllServices = () => {
    return instance.get(`/get-service`);
};

const createNewService = (data) => {
    return instance.post(`/create-new-service`, data);
};


const saveDetailServiceService = (data) => {
    return instance.post(`/create-new-service`, data);
};

const getProfileServiceById = (serviceId) => {
    return instance.get(
        `/get-detail-service-by-id?serviceId=${serviceId}`
    );
};


export {
    getAllServices,
    createNewService,
    saveDetailServiceService,
    getProfileServiceById,
};