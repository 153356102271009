const actionTypes = Object.freeze({

    //admin
    FETCH_ALL_SERVICES_SUCCESS: "FETCH_ALL_SERVICES_SUCCESS",
    FETCH_ALL_SERVICES_FAILDED: "FETCH_ALL_SERVICES_FAILDED",

    FETCH_ALL_OTPS_SUCCESS: "FETCH_ALL_OTPS_SUCCESS",
    FETCH_ALL_OTPS_FAILDED: "FETCH_ALL_OTPS_FAILDED",

    FETCH_ALL_VPSS_SUCCESS: "FETCH_ALL_VPSS_SUCCESS",
    FETCH_ALL_VPSS_FAILDED: "FETCH_ALL_VPSS_FAILDED",

    FETCH_ALL_VPSTIMES_SUCCESS: "FETCH_ALL_VPSTIMES_SUCCESS",
    FETCH_ALL_VPSTIMES_FAILDED: "FETCH_ALL_VPSTIMES_FAILDED",

    FETCH_VPS_SUCCESS: "FETCH_VPS_SUCCESS",
    FETCH_VPS_FAILDED: "FETCH_VPS_FAILDED",

    CREATE_VPS_SUCCESS: "CREATE_VPS_SUCCESS",
    CREATE_VPS_FAILDED: "CREATE_VPS_FAILDED",

    
    DELETE_VPS_SUCCESS: "DELETE_VPS_SUCCESS",
    DELETE_VPS_FAILDED: "DELETE_VPS_FAILDED",
    
    DELETE_VPSTIME_SUCCESS: "DELETE_VPSTIME_SUCCESS",
    DELETE_VPSTIME_FAILDED: "DELETE_VPSTIME_FAILDED",

    EDIT_VPS_SUCCESS: "EDIT_VPS_SUCCESS",
    EDIT_VPS_FAILDED: "EDIT_VPS_FAILDED",
    
    CREATE_SERVICE_SUCCESS: "CREATE_SERVICE_SUCCESS",
    CREATE_SERVICE_FAILDED: "CREATE_SERVICE_FAILDED",
    
    SAVE_DETAIL_SERVICE_SUCCESS: "SAVE_DETAIL_SERVICE_SUCCESS",
    SAVE_DETAIL_SERVICE_FAILDED: "SAVE_DETAIL_SERVICE_FAILDED",
});

export default actionTypes;